<template>
  <div>
    <p>每购买钜惠年货套组(CN-N000125-25)（一个）请选择1个护膝及1个护腰</p>
    <el-table
        border
        :data="HuXi_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CNN00012525_HX_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-table
        border
        :data="HuYao_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CNN00012525_HY_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "CNN00011824",
  data(){
    return{
      tableKey:0,
      HuXi_CN_list:[
        {
          name:'健康护膝(M)',
          code:'CN-T001-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id:-920
        },{
          name:'健康护膝(L)',
          code:'CN-T001-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -921
        },{
          name:'健康护膝(XL)',
          code:'CN-T001-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -922
        },{
          name:'健康护膝(特大)',
          code:'CN-T001-01-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -923
        }
      ],
      HuYao_CN_list:[
        {
          name:'健康磁性护腰(M)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -924
        },{
          name:'健康磁性护腰(L)',
          code:'CN-T003-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -925
        },{
          name:'健康磁性护腰(XL)',
          code:'CN-T003-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -926
        },{
          name:'健康磁性护腰(XXL)',
          code:'CN-T003-01-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'赠品',
          id: -927
        },
      ],
    }
  },
  props: {
    CNN00012525num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'CNN00012525num': function (val) {
      if(val===0){
        this.HuXi_CN_list[0].quantity = 0
        this.HuXi_CN_list[1].quantity = 0
        this.HuXi_CN_list[2].quantity = 0
        this.HuXi_CN_list[3].quantity = 0
        this.HuYao_CN_list[0].quantity = 0
        this.HuYao_CN_list[1].quantity = 0
        this.HuYao_CN_list[2].quantity = 0
        this.HuYao_CN_list[3].quantity = 0
      }
    },
  },
  methods:{
    CNN00012525_HX_Change(){
      // let num = 0
      // let list = []
      // this.HuXi_CN_list.forEach(v=>{
      //   num += v.quantity
      // })
      // if(num !== this.CNN00012525num ){
      //   console.log(111,'hx',num)
      // }else{
      //   this.HuXi_CN_list.forEach(v=>{
      //     if(v.quantity > 0){
      //       list.push(v)
      //     }
      //   })
      // }
      let list = []
      this.HuXi_CN_list.forEach(v=>{
        if(v.quantity > 0){
          list.push(v)
        }
      })
      this.$emit('CNN00012525_HX_Change', list)
    },
    CNN00012525_HY_Change(){
      // let num = 0
      // let list = []
      // this.HuYao_CN_list.forEach(v=>{
      //   num += v.quantity
      // })
      // if(num !== this.CNN00012525num){
      //   console.log(111,'hy',num,this.CNN00012525num)
      // }else{
      //   this.HuYao_CN_list.forEach(v=>{
      //     if(v.quantity > 0){
      //       list.push(v)
      //     }
      //   })
      // }
      let list = []
      this.HuYao_CN_list.forEach(v=>{
        if(v.quantity > 0){
          list.push(v)
        }
      })
      this.$emit('CNN00012525_HY_Change', list)
    },
  }
}
</script>

<style scoped>

</style>
